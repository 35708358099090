import dayjs from "dayjs";
import {
  checkStringNullOrEmpty,
  find_hotel_3_day,
  find_str_in_str,
  checkString,
  createNumberTypeRoom,
} from "../../utils/tool";

export const CreateContent = (operation_details, booking_details) => {
  let content_init = "";

  if (operation_details.vendor_type === "hotel") {
    let day_arr = [];
    let max_day = operation_details.data[0].day_index;
    let day_item = [operation_details.data[0].day_index];

    for (let i = 1; i < operation_details.data.length; i++) {
      let e = operation_details.data[i];

      if (e.day_index == max_day + 1) {
        day_item.push(e.day_index);
        max_day = e.day_index;
      } else {
        day_arr.push(day_item);
        day_item = [e.day_index];
        max_day = e.day_index;
      }
    }

    day_arr.push(day_item);
    console.log(day_arr);

    let hotel_call_html = ``;

    day_arr.map((itemx, indexx) => {
      let checkin_value = dayjs(booking_details.day_start, "DD/MM/YYYY")
        .add(itemx[0], "day")
        .format("DD-MMM-YYYY");
      let checkout_value = dayjs(booking_details.day_start, "DD/MM/YYYY")
        .add(itemx[itemx.length - 1] + 1, "day")
        .format("DD-MMM-YYYY");
      // hotel_call_html

      hotel_call_html =
        hotel_call_html +
        `
        <tr>
            <td colspan="1" rowspan="3" colwidth="69" style="background-color: null">
            <p>Call ${indexx + 1}</p>
            </td>
            <td colspan="1" rowspan="1" colwidth="207" style="background-color: null">
            <p>Number and type of room</p>
            </td>
            <td colspan="1" rowspan="1" colwidth="255" style="background-color: null">
            <p>${createNumberTypeRoom(
              booking_details.room_type,
              booking_details.activities[itemx[0]].accommondation.type
            )}</p>
            </td>
        </tr>
        <tr>
            <td colspan="1" rowspan="1" colwidth="207" style="background-color: null">
            <p>Check-in</p>
            </td>
            <td colspan="1" 
            rowspan="1" colwidth="255" style="background-color: null">
            <p>${checkString(checkin_value)}</p>
            </td>
        </tr>
        <tr>
            <td colspan="1" rowspan="1"
            colwidth="207" style="background-color: null">
            <p>Check-out</p>
            </td>
            <td colspan="1" rowspan="1" colwidth="255"
            style="background-color: null">
            <p>${checkString(checkout_value)}</p>
            </td>
        </tr>`;
    });

    // console.log(operation_details);

    content_init = `
        <h3>Dear ${
          checkString(operation_details.data_vendor.user_receive_email) == ""
            ? "team"
            : checkString(operation_details.data_vendor.user_receive_email)
        },</h3>
    <p>Please check and confirm the booking as below:</p>
    <table style="width: 531px">
       <colgroup>
          <col style="width: 69px">
          <col style="width: 207px">
          <col style="width: 255px">
       </colgroup>
       <tbody>
          <tr>
             <td 
                colspan="2" rowspan="1" colwidth="69,207" style="background-color: null">
                <p>Code</p>
             </td>
             <td colspan="1" rowspan="1" colwidth="255" 
                style="background-color: null">
                <p>${checkString(booking_details.booking_number)}</p>
             </td>
          </tr>
          <tr>
             <td colspan="2" rowspan="1" colwidth="69,207" style="background-color: null">
                <p>Number of guests</p>
             </td>
             <td colspan="1" rowspan="1" colwidth="255" style="background-color: null">
                <p>${checkString(booking_details.no_of_client)}</p>
             </td>
          </tr>
          <tr>
             <td colspan="2" rowspan="1" colwidth="69,207" style="background-color: null">
                <p>Guest name</p>
             </td>
             <td colspan="1"
                rowspan="1" colwidth="255" style="background-color: null">
                <p>${checkString(booking_details.customer_name)}</p>
             </td>
          </tr>
          <tr>
             <td colspan="2" rowspan="1" style="background-color: null"
                colwidth="69,207">
                <p>Hotel</p>
             </td>
             <td colspan="1" rowspan="1" colwidth="255" style="background-color: null">
                <p>${checkString(operation_details.data_vendor.name)}</p>
             </td>
          </tr>
          <tr>
             <td colspan="2" rowspan="1" colwidth="69,207" style="background-color: null">
                <p>Special requirement</p>
             </td>
             <td 
                colspan="1" rowspan="1" colwidth="255" style="background-color: null">
                <p>Nice room</p>
             </td>
          </tr>
          <tr>
             <td colspan="2"
                rowspan="1" colwidth="69,207" style="background-color: null">
                <p>Rate &amp; payment</p>
             </td>
             <td colspan="1" rowspan="1" colwidth="255" 
                style="background-color: null">
                <p>TBA</p>
             </td>
          </tr>
          ${checkString(hotel_call_html)}
       </tbody>
    </table>
        `;
  }

  if (operation_details.vendor_type === "tour") {
    let tour_call_html = ``;
    operation_details.data.map((itemx, indexx) => {
      let travel_date = dayjs(booking_details.day_start, "DD/MM/YYYY")
        .add(itemx.day_index, "day")
        .format("DD-MMM-YYYY");

      tour_call_html =
        tour_call_html +
        `<tr>
            <td colspan="2" rowspan="1" colwidth="182,255" style="background-color: null">
            <p></p>
            </td>
        </tr>
        <tr>
            <td colspan="1" rowspan="1" colwidth="182" style="background-color: null">
               <p>Tour name</p>
            </td>
            <td colspan="1" rowspan="1" colwidth="255" style="background-color: null">
               <p>${checkString(itemx.service_code)}</p>
            </td>
         </tr>
        <tr>
            <td colspan="1" rowspan="1" colwidth="182" style="background-color: null">
            <p>Travel date</p>
            </td>
            <td colspan="1" rowspan="1" colwidth="255" style="background-color: null">
            <p>${checkString(travel_date)}</p>
            </td>
        </tr>
        <tr>
            <td colspan="1" rowspan="1" colwidth="182" style="background-color: null">
            <p>Pick up time</p>
            </td>
            <td colspan="1" rowspan="1" colwidth="255" style="background-color: null">
            <p>TBA</p>
            </td>
        </tr>
        <tr>
            <td colspan="1" rowspan="1" colwidth="182" style="background-color: null">
            <p>Rate &amp; payment</p>
            </td>
            <td colspan="1" rowspan="1" colwidth="255" style="background-color: null">
            <p>TBA</p>
            </td>
        </tr>`;

      let day_pickup = itemx.day_index;
      let day_dropoff = day_pickup + 1;
      let acti_pickup = null;
      let acti_dropoff = null;

      try {
        acti_pickup = booking_details.activities[day_pickup].accommondation;
      } catch (error) {
        console.log(error);
      }

      if (acti_pickup != null) {
        tour_call_html += `
         <tr>
            <td colspan="1" rowspan="1" colwidth="182" style="background-color: null">
            <p>Pick up at</p>
            </td>
            <td colspan="1" rowspan="1" colwidth="255" style="background-color: null">
            <p>${checkString(acti_pickup?.hotel)} ${checkString(
          acti_pickup?.address
        )}</p>
            </td>
        </tr>
        `;
      }

      try {
        acti_dropoff = booking_details.activities[day_dropoff].accommondation;
      } catch (error) {
        console.log(error);
      }

      if (acti_dropoff != null) {
        tour_call_html += `
         <tr>
            <td colspan="1" rowspan="1" colwidth="182" style="background-color: null">
            <p>Drop off at</p>
            </td>
            <td colspan="1" rowspan="1" colwidth="255" style="background-color: null">
            <p>${checkString(acti_dropoff?.hotel)} ${checkString(
          acti_dropoff?.address
        )}</p>
            </td>
        </tr>`;
      } else {
        tour_call_html += `
         <tr>
            <td colspan="1" rowspan="1" colwidth="182">
            <p>Drop off at</p>
            </td>
            <td colspan="1" rowspan="1" colwidth="255" style="background-color: null">
            <p></p>
            </td>
        </tr>`;
      }
    });

    content_init = `
<h3>Dear Team,</h3>
<p>Please check and confirm the booking as below:</p>
<table style="width: 437px">
   <colgroup>
      <col style="width: 182px">
      <col style="width: 255px">
   </colgroup>
   <tbody>
      <tr>
         <td colspan="1" rowspan="1" colwidth="182" style="background-color: null">
            <p>Code</p>
         </td>
         <td colspan="1" rowspan="1" colwidth="255" style="background-color: null">
            <p>${checkString(booking_details.booking_number)}</p>
         </td>
      </tr>
      <tr>
         <td colspan="1" rowspan="1" colwidth="182" style="background-color: null">
            <p>Number of guests</p>
         </td>
         <td colspan="1" rowspan="1" colwidth="255" style="background-color: null">
            <p>${checkString(booking_details.no_of_client)}</p>
         </td>
      </tr>
      <tr>
         <td colspan="1" rowspan="1" colwidth="182" style="background-color: null">
            <p>Guest name</p>
         </td>
         <td colspan="1" rowspan="1" colwidth="255" style="background-color: null">
            <p>${checkString(booking_details.customer_name)}</p>
         </td>
      </tr>
      ${tour_call_html}
   </tbody>
</table>
    `;
  }

  if (operation_details.vendor_type === "cruise") {
    let day_arr = [];
    let max_day = operation_details.data[0].day_index;
    let day_item = [operation_details.data[0].day_index];

    for (let i = 1; i < operation_details.data.length; i++) {
      let e = operation_details.data[i];

      if (e.day_index == max_day + 1) {
        day_item.push(e.day_index);
        max_day = e.day_index;
      } else {
        day_arr.push(day_item);
        day_item = [e.day_index];
        max_day = e.day_index;
      }
    }

    let tour_call_html = ``;

    day_arr.push(day_item);
    day_arr.map((itemx, indexx) => {
      let travel_date = dayjs(booking_details.day_start, "DD/MM/YYYY")
        .add(itemx[0], "day")
        .format("DD-MMM-YYYY");

      let travel_date_out = dayjs(booking_details.day_start, "DD/MM/YYYY")
        .add(itemx[itemx.length - 1] + 1, "day")
        .format("DD-MMM-YYYY");

      tour_call_html =
        tour_call_html +
        `
      <tr>
         <th colspan="2" rowspan="1" colwidth="182,255" style="background-color: null">
         <p></p>
         </td>
      </tr>
      <tr>
         <td colspan="1" rowspan="1" colwidth="182" style="background-color: null">
            <p>Number and type of room</p>
         </td>
         <td colspan="1" rowspan="1" colwidth="255" style="background-color: null">
            <p>${createNumberTypeRoom(
              booking_details.room_type,
              booking_details.activities[itemx[0]].accommondation.type
            )}</p>
         </td>
      </tr>
      <tr>
         <td colspan="1" rowspan="1" colwidth="182" style="background-color: null">
         <p>Check in</p>
         </td>
         <td colspan="1" rowspan="1" colwidth="255" style="background-color: null">
         <p>${checkString(travel_date)}</p>
         </td>
      </tr>
      <tr>
         <td colspan="1" rowspan="1" colwidth="182" style="background-color: null">
         <p>Check out</p>
         </td>
         <td colspan="1" rowspan="1" colwidth="255" style="background-color: null">
         <p>${checkString(travel_date_out)}</p>
         </td>
      </tr>`;

      let day_pickup = itemx[0] - 1;
      let day_dropoff = itemx[itemx.length - 1] + 1;
      let acti_pickup = null;
      let acti_dropoff = null;

      let pickuptime = null;

      try {
        acti_pickup = booking_details.activities[day_pickup].accommondation;
        pickuptime = booking_details.activities[day_pickup].pickuptime;
      } catch (error) {
        console.log(error);
      }

      if (acti_pickup != null) {
        tour_call_html += `
        <tr>
           <td colspan="1" rowspan="1" colwidth="182" style="background-color: null">
           <p>Pick up time</p>
           </td>
           <td colspan="1" rowspan="1" colwidth="255" style="background-color: null">
           <p>${checkString(pickuptime)}</p>
           </td>
       </tr>
        <tr>
           <td colspan="1" rowspan="1" colwidth="182" style="background-color: null">
           <p>Pick up at</p>
           </td>
           <td colspan="1" rowspan="1" colwidth="255" style="background-color: null">
           <p>${checkString(acti_pickup?.hotel)} ${checkString(
          acti_pickup?.address
        )}</p>
           </td>
       </tr>
       `;
      }

      try {
        acti_dropoff = booking_details.activities[day_dropoff].accommondation;
      } catch (error) {
        console.log(error);
      }

      if (acti_dropoff != null) {
        tour_call_html += `
        <tr>
           <td colspan="1" rowspan="1" colwidth="182" style="background-color: null">
           <p>Drop off at</p>
           </td>
           <td colspan="1" rowspan="1" colwidth="255" style="background-color: null">
           <p>${checkString(acti_dropoff?.hotel)} ${checkString(
          acti_dropoff?.address
        )}</p>
           </td>
       </tr>`;
      } else {
        tour_call_html += `
        <tr>
           <td colspan="1" rowspan="1" colwidth="182" style="background-color: null">
           <p>Drop off at</p>
           </td>
           <td colspan="1" rowspan="1" colwidth="255" style="background-color: null">
           <p></p>
           </td>
       </tr>`;
      }
    });

    content_init = `
<h3>Dear ${checkString(operation_details.data_vendor.user_receive_email)},</h3>
<p>Please check and confirm the booking as below:</p>
<table style="width: 437px">
  <colgroup>
     <col style="width: 182px">
     <col style="width: 255px">
  </colgroup>
  <tbody>
     <tr>
        <td colspan="1" rowspan="1" colwidth="182" style="background-color: null">
           <p>Code</p>
        </td>
        <td colspan="1" rowspan="1" colwidth="255" style="background-color: null">
           <p>${checkString(booking_details.booking_number)}</p>
        </td>
     </tr>
     <tr>
        <td colspan="1" rowspan="1" colwidth="182" style="background-color: null">
           <p>Number of guests</p>
        </td>
        <td colspan="1" rowspan="1" colwidth="255" style="background-color: null">
           <p>${checkString(booking_details.no_of_client)}</p>
        </td>
     </tr>
     <tr>
        <td colspan="1" rowspan="1" colwidth="182" style="background-color: null">
           <p>Guest name</p>
        </td>
        <td colspan="1" rowspan="1" colwidth="255" style="background-color: null">
           <p>${checkString(booking_details.customer_name)}</p>
        </td>
     </tr>
     <tr>
        <td colspan="1" rowspan="1" colwidth="182" style="background-color: null">
           <p>Cruise</p>
        </td>
        <td colspan="1" rowspan="1" colwidth="255" style="background-color: null">
           <p>${checkString(operation_details.data_vendor.name)}</p>
        </td>
     </tr>
     <tr>
        <td colspan="1" rowspan="1" colwidth="182" style="background-color: null">
           <p>Special requirement</p>
        </td>
        <td colspan="1" rowspan="1" colwidth="255" style="background-color: null">
           <p>Nice room, far from engine</p>
        </td>
     </tr>
     <tr>
      <td colspan="1" rowspan="1" colwidth="182" style="background-color: null">
         <p>Rate and payment</p>
      </td>
      <td colspan="1" rowspan="1" colwidth="255" style="background-color: null">
         <p>TBA</p>
      </td>
     </tr>
     ${tour_call_html}
  </tbody>
</table>
   `;
  }
  if (operation_details.vendor_type === "transportation") {
    let tour_call_html = ``;

    operation_details.data.map((itemx, indexx) => {
      let travel_date = dayjs(booking_details.day_start, "DD/MM/YYYY")
        .add(itemx.day_index, "day")
        .format("DD-MMM-YYYY");

      let pickup_place = "";
      let dropoff_place = "";

      let hotel_3_day = find_hotel_3_day(booking_details, itemx.day_index);

      if (checkStringNullOrEmpty(itemx.pickup_place)) {
        if (find_str_in_str(itemx.service_code, "tien")) {
          pickup_place = hotel_3_day.hotel_yesterday;
        }
      } else {
        pickup_place = itemx.pickup_place;
      }

      if (checkStringNullOrEmpty(itemx.dropoff_place)) {
        if (find_str_in_str(itemx.service_code, "don")) {
          dropoff_place = hotel_3_day.hotel_this_day;
        }
      } else {
        dropoff_place = itemx.dropoff_place;
      }

      let show_flight_no = "";
      if (
        find_str_in_str(itemx.service_code, "don sb") ||
        find_str_in_str(itemx.service_code, "tien sb")
      ) {
        show_flight_no = `
         <tr>
            <td colspan="1" rowspan="1" colwidth="182" style="background-color: null">
            <p>Flight No.</p>
            </td>
            <td colspan="1" rowspan="1" colwidth="255" style="background-color: null">
            <p></p>
            </td>
        </tr>
         `;
      }

      tour_call_html =
        tour_call_html +
        `
        <tr>
            <td colspan="2" rowspan="1" colwidth="182,255" style="background-color: null">
            <p></p>
            </td>
        </tr>
        <tr>
            <td colspan="1" rowspan="1" colwidth="182" style="background-color: null">
            <p>Pick up date & time</p>
            </td>
            <td colspan="1" rowspan="1" colwidth="255" style="background-color: null">
            <p>${checkString(travel_date)}</p>
            </td>
        </tr>
        ${checkString(show_flight_no)}
        <tr>
            <td colspan="1" rowspan="1" colwidth="182" style="background-color: null">
            <p>Pick up place</p>
            </td>
            <td colspan="1" rowspan="1" colwidth="255" style="background-color: null">
            <p>${checkString(pickup_place)}</p>
            </td>
        </tr>
        <tr>
            <td colspan="1" rowspan="1" colwidth="182" style="background-color: null">
            <p>Drop off</p>
            </td>
            <td colspan="1" rowspan="1" colwidth="255" style="background-color: null">
            <p>${checkString(dropoff_place)}</p>
            </td>
        </tr>
        
        `;
    });

    content_init = `
<h3>Dear ${checkString(operation_details.data_vendor.user_receive_email)},</h3>
<p>I would like to book pick up service as below:</p>
<table style="width: 437px">
   <colgroup>
      <col style="width: 182px">
      <col style="width: 255px">
   </colgroup>
   <tbody>
      <tr>
         <td colspan="1" rowspan="1" colwidth="182" style="background-color: null">
            <p>Code</p>
         </td>
         <td colspan="1" rowspan="1" colwidth="255" style="background-color: null">
            <p>${checkString(booking_details.booking_number)}</p>
         </td>
      </tr>
      <tr>
         <td colspan="1" rowspan="1" colwidth="182" style="background-color: null">
            <p>Number of guests</p>
         </td>
         <td colspan="1" rowspan="1" colwidth="255" style="background-color: null">
            <p>${checkString(booking_details.no_of_client)}</p>
         </td>
      </tr>
      <tr>
         <td colspan="1" rowspan="1" colwidth="182" style="background-color: null">
            <p>Guest name</p>
         </td>
         <td colspan="1" rowspan="1" colwidth="255" style="background-color: null">
            <p>${checkString(booking_details.customer_name)}</p>
         </td>
      </tr>
      ${tour_call_html}
   </tbody>
</table>
    `;
  }

  return content_init;
};
