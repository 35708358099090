import React, { useState } from "react";
import { Document, Page, Text, PDFViewer, View } from "@react-pdf/renderer";

import { styles } from "./AppStyle";
import {
  HeadingWithColor,
  Detail2Column,
  TableRowDetail,
  TableHeaderDetail,
  PriceDetail,
  PriceList,
  InfoCompany,
  TableHeaderDetailAcc,
  TableRowDetailAcc,
  TableRowNoteHotel,
} from "./TableCom";
import {
  FooterLine,
  HeaderCus,
  FooterLeft,
  FooterRight,
  FooterPageNum,
} from "./HeaderFooter";
import { gen_day } from "../../utils/dayshow";

export default function TabPDFDetail({ data_pdf }) {
  if (data_pdf == null) {
    return null;
  } else {
    const day_showing = gen_day(data_pdf.day_start, data_pdf.activities.length);
    const title_pdf = data_pdf.booking_number + ".pdf";

    return (
      <PDFViewer style={{ flex: 1 }}>
        <Document title={title_pdf}>
          <Page style={styles.body}>
            <HeaderCus />
            <Text style={styles.title}>{data_pdf.detail_title_pdf}</Text>
            <HeadingWithColor label="BOOKED BY" />
            <Detail2Column label="Prepared by" value={data_pdf.prepared_by} />
            <Detail2Column
              label="Specially designed for"
              value={data_pdf.customer_name}
            />
            <Detail2Column
              label="Cell/Whatsapp"
              value={data_pdf.customer_cell}
            />
            <Detail2Column label="Email" value={data_pdf.customer_email} />
            <HeadingWithColor label="BOOKING DETAILS" />
            <Detail2Column
              label="Booking REF"
              value={data_pdf.booking_number}
            />
            <Detail2Column
              label="No of clients"
              value={data_pdf.no_of_client}
            />
            <Detail2Column label="Check in" value={data_pdf.check_in} />
            <Detail2Column label="Check out" value={data_pdf.check_out} />
            <Detail2Column label="Package" value={data_pdf.package} />
            <Detail2Column label="Room type" value={data_pdf.room_type} />
            <Detail2Column label="Tour type" value={data_pdf.tour_type} />
            <Detail2Column
              label="Arrival Airport"
              value={data_pdf.arrival_airport}
            />
            <Detail2Column
              label="Departure Airport"
              value={data_pdf.departure_airport}
            />
            <Detail2Column
              label="Arrival flight"
              value={data_pdf.arrival_flight}
            />
            <Detail2Column
              label="Departure flight"
              value={data_pdf.departure_flight}
            />
            <Detail2Column label="Tour guide" value={data_pdf.tour_guide} />
            <Detail2Column
              label="Special food requirements"
              value={data_pdf.special_food_requirements}
            />
            <HeadingWithColor label="BRIEF ITINERARY" />
            <TableHeaderDetail />
            {data_pdf.activities.map((item, index) => (
              <TableRowDetail
                key={index}
                data={item}
                index={index}
                day_showing={day_showing}
              />
            ))}
            <HeadingWithColor label="ACCOMMODATION" />
            <Text
              style={{
                marginLeft: 10,
                marginTop: 3,
                marginBottom: 3,
                fontSize: 11,
                textAlign: "left",
                fontFamily: "Helvetica-Bold",
              }}
            >
              Number and type of room: {data_pdf.room_type}
            </Text>
            <TableHeaderDetailAcc />
            {data_pdf.accommondation.map((item, index) => (
              <TableRowDetailAcc data={item} index={index} />
            ))}
            <TableRowNoteHotel />
            <HeadingWithColor label="PRICE" />
            {data_pdf.price.map((item, index) => (
              <PriceDetail
                label={item.label}
                value={item.value}
                price={item.price}
              />
            ))}
            <Text style={styles.textBold}>Includes:</Text>
            {data_pdf.price_include.split("\n").map((item, index) => (
              <PriceList label={item} />
            ))}
            <Text style={styles.textBold}>Excludes:</Text>
            {data_pdf.price_exclude.split("\n").map((item, index) => (
              <PriceList label={item} />
            ))}
            <HeadingWithColor label="ITINERARY" />
            {data_pdf.activities.map((item, index) => (
              <PriceDetail
                label={
                  day_showing[index].day_details +
                  ": " +
                  item.activities
                    .toUpperCase()
                    .replaceAll("\n", " ")
                    .split("(")[0]
                }
                value={item.detail}
              />
            ))}
            <Text style={styles.notetext}>
              Thank you for travelling with us
            </Text>
            <Text style={styles.textBold}>REMEMBER TO BRING</Text>
            {data_pdf.remember_to_bring.split("\n").map((item, index) => (
              <PriceList label={item} />
            ))}
            <Text style={styles.textBold}>NOTE</Text>
            <Text style={styles.textNote}>{data_pdf.note}</Text>
            <Text style={{ ...styles.textBold, marginBottom: 10 }}>
              S VIETNAM ADVENTURES TRAVEL CO.,LTD
            </Text>
            <InfoCompany label="Tel/ Whatsapp" value=": +84 84 579 4384" />
            <InfoCompany label="Email" value=": info@svietnamadventures.com" />
            <InfoCompany label="Website" value=": www.svietnamadventures.com" />
            <FooterLine />
            <FooterLeft />
            <FooterPageNum />
            <FooterRight />
          </Page>
        </Document>
      </PDFViewer>
    );
  }
}
