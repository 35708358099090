import { StyleSheet } from "@react-pdf/renderer";

import React from "react";
import { Text, Line, Svg, Image } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 15,
    textAlign: "center",
    fontFamily: "Helvetica",
  },
  author: {
    fontSize: 12,
    textAlign: "center",
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    fontFamily: "Helvetica",
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: "justify",
    fontFamily: "Helvetica",
  },
  textUser: {
    margin: 12,
    fontSize: 12,
    textAlign: "justify",
    fontFamily: "Helvetica",
    fontWeight: "bold",
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  imageheader: {
    height: 60,
    top: -15,
    alignSelf: "center",
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 10,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  pageNumberLeft: {
    position: "absolute",
    fontSize: 10,
    bottom: 20,
    left: 40,
    right: 0,
    fontFamily: "Helvetica-Oblique",
    textAlign: "left",
    color: "grey",
  },
  pageNumberRight: {
    position: "absolute",
    fontSize: 10,
    bottom: 20,
    left: 0,
    right: 40,
    textAlign: "right",
    color: "grey",
    fontFamily: "Helvetica-Oblique",
  },
  lineFooter: {
    position: "absolute",
    bottom: 0,
    // left: 40,
    // right: 40,
  },
});

const Subtitle = ({ children, ...props }) => (
  <Text style={styles.subtitle} {...props}>
    {children}
  </Text>
);

const HeaderCus = ({ children, ...props }) => (
  <Image style={styles.imageheader} src="./LOGO.jpg" fixed></Image>
);
const FooterLine = ({ children, ...props }) => (
  <Svg height="50" width="600" fixed style={styles.lineFooter}>
    <Line x1="0" y1="0" x2="600" y2="0" strokeWidth={2} stroke="gray" />
  </Svg>
);

const FooterLeft = ({ children, ...props }) => (
  <Text style={styles.pageNumberLeft} fixed>
    Tel: +84 84 579 4384
    {"\n"}
    WhatsApp: +84 84 579 4384
  </Text>
);

const FooterRight = ({ children, ...props }) => (
  <Text style={styles.pageNumberRight} fixed>
    Email: info@svietnamadventures.com
    {"\n"}
    Website: svietnamadventures.com
  </Text>
);

const FooterPageNum = ({ children, ...props }) => (
  <Text
    style={styles.pageNumber}
    render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
    fixed
  />
);

export {
  FooterPageNum,
  FooterRight,
  HeaderCus,
  FooterLine,
  Subtitle,
  FooterLeft,
};
