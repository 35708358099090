import React, { useState, useEffect } from "react";
import { Button, Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { COLUMN_USER, ROLE_USER } from "../utils/constDispatch";
import { API_DelUser, API_EditUser, API_ResetPassword } from "../utils/http";

export default function ListUser({ user_arr, get_user }) {
  const [selected_id, set_selected_id] = useState(null);
  const [rowSelection, setRowSelection] = useState([]);
  const [row_click, set_row_click] = useState(null);

  const [new_user_arr, set_new_user_arr] = useState(user_arr);

  const DeleteUser = (username) => {
    API_DelUser(username, (data) => {
      if (data.success) {
        get_user();
      }
    });
  };

  const ResetUser = (username) => {
    API_ResetPassword(username, (data) => {
      if (data.success) {
        get_user();
      }
    });
  };

  const ChangeRoleUser = (role) => {
    let body = {
      data: {
        role: role,
      },
      _id: row_click._id,
    };

    API_EditUser(body, (data) => {
      if (data.success) {
        get_user();
      }
    });
  };

  return (
    <Box style={{ flexDirection: "column", display: "flex", margin: 20 }}>
      {row_click == null ? null : (
        <Box>
          <Button
            style={{ margin: 5 }}
            variant="outlined"
            size="small"
            onClick={() => ResetUser(row_click.username)}
          >
            Reset password
          </Button>

          <Button
            style={{ margin: 5 }}
            variant="outlined"
            size="small"
            onClick={() => ChangeRoleUser(ROLE_USER.ADMIN)}
          >
            make admin
          </Button>
          <Button
            style={{ margin: 5 }}
            variant="outlined"
            size="small"
            onClick={() => ChangeRoleUser(ROLE_USER.OPERATOR)}
          >
            make OPERATOR
          </Button>
          <Button
            style={{ margin: 5 }}
            variant="outlined"
            size="small"
            onClick={() => ChangeRoleUser(ROLE_USER.EDITER)}
          >
            make USER
          </Button>
        </Box>
      )}

      {new_user_arr.length === 0 ? null : (
        <DataGrid
          getRowId={(item) => item._id}
          rows={user_arr}
          columns={COLUMN_USER}
          pageSizeOptions={[5, 10, 20]}
          rowSelectionModel={rowSelection}
          onRowSelectionModelChange={(e) => {
            setRowSelection(e);
            set_selected_id(e[0]);
            set_row_click(user_arr.find((x) => x._id === e[0]));
          }}
          disableMultipleRowSelection={true}
        />
      )}
    </Box>
  );
}
