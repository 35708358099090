import React, { useState, useEffect } from "react";
import { TextField, Button, Autocomplete } from "@mui/material";

export default function Addinginput(props) {
  const [init_data, set_init_data] = useState(props.init_data);
  const [value_adding, setValueAdding] = useState(null);

  return (
    <div style={{ flexDirection: "column", display: "flex" }}>
      <TextField
        style={{ margin: 5, flex: 1 }}
        id="label"
        label="label"
        variant="outlined"
        value={init_data.label}
        onChange={(e) => set_init_data({ ...init_data, label: e.target.value })}
      />
      <div style={{ display: "flex" }}>
        <TextField
          style={{ margin: 5, flex: 4 }}
          id="activities"
          label="activities"
          variant="outlined"
          multiline
          minRows={3}
          value={init_data.activities}
          onChange={(e) =>
            set_init_data({ ...init_data, activities: e.target.value })
          }
        />
        <Autocomplete
          isOptionEqualToValue={(option, value) => option.id === value.id}
          disablePortal
          disableClearable
          id="combo-box-demo"
          options={props.accom}
          getOptionLabel={(option) => option.code}
          sx={{ width: 200, marginTop: 5 }}
          defaultValue={{ code: props.init_data.accomodation_code }}
          onChange={(e, value) => setValueAdding(value)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Accommondation code"
              value={params.code}
            />
          )}
        />
      </div>

      <TextField
        style={{ margin: 5, flex: 3 }}
        id="activities_detail"
        label="activities detail"
        variant="outlined"
        multiline
        minRows={5}
        value={init_data.detail}
        onChange={(e) =>
          set_init_data({ ...init_data, detail: e.target.value })
        }
      />
      <div style={{ display: "flex", flexDirection: "row" }}>
        <TextField
          style={{ margin: 5, flex: 3 }}
          id="pickuptime"
          label="pickup time"
          variant="outlined"
          multiline
          minRows={2}
          value={init_data.pickuptime}
          onChange={(e) =>
            set_init_data({ ...init_data, pickuptime: e.target.value })
          }
        />

        <TextField
          style={{ margin: 5, flex: 3 }}
          id="transportation"
          label="transportation"
          variant="outlined"
          value={init_data.transportation}
          onChange={(e) =>
            set_init_data({ ...init_data, transportation: e.target.value })
          }
        />
        <TextField
          style={{ margin: 5, flex: 3 }}
          id="guide"
          label="guide"
          variant="outlined"
          value={init_data.guide}
          onChange={(e) =>
            set_init_data({ ...init_data, guide: e.target.value })
          }
        />
      </div>
      <Button
        variant="contained"
        onClick={() => {
          let data = init_data;

          if (value_adding != null) {
            data.accomodation_code = value_adding.code;
          }

          props.onSaveClick(props.init_data._id, data);
        }}
      >
        save
      </Button>
    </div>
  );
}
