import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import {
  render_number,
  render_vnd_to_aud,
  render_vnd_to_usd,
} from "../../utils/tool";

export default function BoxTotal({ title, price }) {
  return (
    <Box
      sx={{
        padding: 2,
        borderStyle: "solid",
        borderWidth: "thin",
        borderColor: "#B0BEC5",
        backgroundColor: "#FFF9C4",
      }}
    >
      <Typography variant="h6">{title}</Typography>
      <Typography>
        {render_number(price)}
        {" VND"}
      </Typography>
      <Typography>
        {render_vnd_to_aud(price)}
        {" AUD"}
      </Typography>

      <Typography>
        {render_vnd_to_usd(price)}
        {" USD"}
      </Typography>
    </Box>
  );
}
