import dayjs from "dayjs";

function gen_day(day_start, day_length) {
  let day_showing = [];
  if (day_start == null) {
    for (let i = 0; i < day_length; i++) {
      day_showing.push({
        day: "Day " + (i + 1).toString(),
        date: "TBA",
        day_details: "DAY " + (i + 1).toString(),
      });
    }
  } else {
    let day_init = dayjs(day_start, "DD/MM/YYYY");

    for (let i = 0; i < day_length; i++) {
      let day_details =
        "DAY " +
        (i + 1).toString() +
        " - " +
        day_init.add(i, "day").format("D MMM YYYY").toUpperCase();

      day_showing.push({
        day: "Day " + (i + 1).toString(),
        date: day_init.add(i, "day").format("D MMM YYYY"),
        day_details: day_details,
      });
    }
  }

  return day_showing;
}

export { gen_day };
