import React, { useState } from "react";
import { TextField, Button } from "@mui/material";

export default function AccomInput(props) {
  const [init_data, set_init_data] = useState(props.init_data);

  return (
    <div style={{ flexDirection: "column", display: "flex" }}>
      <TextField
        style={{ margin: 5, flex: 1 }}
        id="code"
        label="code"
        variant="outlined"
        value={init_data.code}
        onChange={(e) => set_init_data({ ...init_data, code: e.target.value })}
      />
      <TextField
        style={{ margin: 5, flex: 1 }}
        id="city"
        label="city"
        variant="outlined"
        value={init_data.city}
        onChange={(e) => set_init_data({ ...init_data, city: e.target.value })}
      />
      <TextField
        style={{ margin: 5, flex: 4 }}
        id="hotel"
        label="hotel"
        variant="outlined"
        multiline
        minRows={2}
        value={init_data.hotel}
        onChange={(e) => set_init_data({ ...init_data, hotel: e.target.value })}
      />
      <TextField
        style={{ margin: 5, flex: 4 }}
        id="address"
        label="address"
        variant="outlined"
        value={init_data.address}
        onChange={(e) =>
          set_init_data({ ...init_data, address: e.target.value })
        }
      />
      <div style={{ display: "flex", flexDirection: "row" }}>
        <TextField
          style={{ margin: 5, flex: 3 }}
          id="type"
          label="type"
          variant="outlined"
          value={init_data.type}
          onChange={(e) =>
            set_init_data({ ...init_data, type: e.target.value })
          }
        />
        <TextField
          style={{ margin: 5, flex: 3 }}
          id="status"
          label="status"
          variant="outlined"
          value={init_data.status}
          onChange={(e) =>
            set_init_data({ ...init_data, status: e.target.value })
          }
        />
        <TextField
          style={{ margin: 5, flex: 3 }}
          id="remark"
          label="remark"
          variant="outlined"
          value={init_data.remark}
          onChange={(e) =>
            set_init_data({ ...init_data, remark: e.target.value })
          }
        />
      </div>
      <Button
        variant="contained"
        onClick={() => {
          props.onSaveClick(props.init_data._id, init_data);
        }}
      >
        save
      </Button>
    </div>
  );
}
