import { createContext, useContext, useReducer } from "react";
import { dispatchTYPE } from "./utils/constDispatch";

const TasksContext = createContext(null);
const TasksDispatchContext = createContext(null);

export function TasksProvider({ children }) {
  const [data_pdf, dispatch] = useReducer(tasksReducer, initialTasks);

  return (
    <TasksContext.Provider value={data_pdf}>
      <TasksDispatchContext.Provider value={dispatch}>
        {children}
      </TasksDispatchContext.Provider>
    </TasksContext.Provider>
  );
}

export function useTasks() {
  return useContext(TasksContext);
}

export function useTasksDispatch() {
  return useContext(TasksDispatchContext);
}

const initialTasks = {
  _setting: {
    login: {
      status: false,
    },
    user: {
      username: null,
      role: 0,
    },
  },
};

function tasksReducer(data_pdf, action) {
  switch (action.type) {
    case dispatchTYPE.login: {
      return {
        ...data_pdf,
        _setting: {
          user: action.user,
          login: action.login,
        },
      };
    }
    case dispatchTYPE.logout: {
      return initialTasks;
    }
    case dispatchTYPE.get_data_pdf: {
      return { ...data_pdf, ...action.value };
    }
    case dispatchTYPE.booking_number: {
      return { ...data_pdf, booking_number: action.value };
    }
    case dispatchTYPE.prepared_by: {
      return { ...data_pdf, prepared_by: action.value };
    }
    case dispatchTYPE.customer_name: {
      return { ...data_pdf, customer_name: action.value };
    }
    case dispatchTYPE.customer_cell: {
      return { ...data_pdf, customer_cell: action.value };
    }
    case dispatchTYPE.customer_email: {
      return { ...data_pdf, customer_email: action.value };
    }
    case dispatchTYPE.no_of_client: {
      return { ...data_pdf, no_of_client: action.value };
    }
    case dispatchTYPE.check_in: {
      return { ...data_pdf, check_in: action.value };
    }
    case dispatchTYPE.check_out: {
      return { ...data_pdf, check_out: action.value };
    }
    case dispatchTYPE.package: {
      return { ...data_pdf, package: action.value };
    }
    case dispatchTYPE.room_type: {
      return { ...data_pdf, room_type: action.value };
    }
    case dispatchTYPE.tour_type: {
      return { ...data_pdf, tour_type: action.value };
    }
    case dispatchTYPE.arrival_airport: {
      return { ...data_pdf, arrival_airport: action.value };
    }
    case dispatchTYPE.arrival_flight: {
      return { ...data_pdf, arrival_flight: action.value };
    }
    case dispatchTYPE.departure_airport: {
      return { ...data_pdf, departure_airport: action.value };
    }
    case dispatchTYPE.departure_flight: {
      return { ...data_pdf, departure_flight: action.value };
    }
    case dispatchTYPE.special_food_requirements: {
      return { ...data_pdf, special_food_requirements: action.value };
    }
    case dispatchTYPE.tour_guide: {
      return { ...data_pdf, tour_guide: action.value };
    }
    case dispatchTYPE.price_include: {
      return { ...data_pdf, price_include: action.value };
    }
    case dispatchTYPE.price_exclude: {
      return { ...data_pdf, price_exclude: action.value };
    }
    case dispatchTYPE.day_start: {
      return { ...data_pdf, day_start: action.value };
    }
    case dispatchTYPE.remember_to_bring: {
      return { ...data_pdf, remember_to_bring: action.value };
    }
    case dispatchTYPE.note: {
      return { ...data_pdf, note: action.value };
    }

    case dispatchTYPE.price_label: {
      let new_item = [...data_pdf.price];

      new_item = new_item.map((t, index) => {
        if (index === action.index_in_array) {
          return { ...t, label: action.value };
        } else {
          return t;
        }
      });

      return { ...data_pdf, price: new_item };
    }

    case dispatchTYPE.price_value: {
      let new_item = [...data_pdf.price];

      new_item = new_item.map((t, index) => {
        if (index === action.index_in_array) {
          return { ...t, value: action.value };
        } else {
          return t;
        }
      });

      return { ...data_pdf, price: new_item };
    }

    case dispatchTYPE.price_price: {
      let new_item = [...data_pdf.price];

      new_item = new_item.map((t, index) => {
        if (index === action.index_in_array) {
          return { ...t, price: action.value };
        } else {
          return t;
        }
      });

      return { ...data_pdf, price: new_item };
    }

    case dispatchTYPE.activities_activities: {
      let new_item = [...data_pdf.activities];
      new_item = new_item.map((t, index) => {
        if (index === action.index_in_array) {
          return { ...t, activities: action.value };
        } else {
          return t;
        }
      });
      return { ...data_pdf, activities: new_item };
    }

    case dispatchTYPE.activities_accommondation: {
      let new_item = [...data_pdf.activities];

      new_item = new_item.map((t, index) => {
        if (index === action.index_in_array) {
          let a = { ...t };
          a.accommondation.hotel = action.value;
          return a;
        } else {
          return t;
        }
      });
      return { ...data_pdf, activities: new_item };
    }
    case dispatchTYPE.activities_accommondation_address: {
      let new_item = [...data_pdf.activities];

      new_item = new_item.map((t, index) => {
        if (index === action.index_in_array) {
          let a = { ...t };
          a.accommondation.address = action.value;
          return a;
        } else {
          return t;
        }
      });
      return { ...data_pdf, activities: new_item };
    }

    case dispatchTYPE.activities_accommondation_type: {
      let new_item = [...data_pdf.activities];

      new_item = new_item.map((t, index) => {
        if (index === action.index_in_array) {
          let a = { ...t };
          a.accommondation.type = action.value;
          return a;
        } else {
          return t;
        }
      });
      return { ...data_pdf, activities: new_item };
    }

    case dispatchTYPE.activities_accommondation_full: {
      let new_item = [...data_pdf.activities];

      new_item = new_item.map((t, index) => {
        if (index === action.index_in_array) {
          let a = { ...t };
          a.accommondation = action.value;
          a.accomodation_code = action.value.code;

          return a;
        } else {
          return t;
        }
      });
      return { ...data_pdf, activities: new_item };
    }

    case dispatchTYPE.activities_details: {
      let new_item = [...data_pdf.activities];
      new_item = new_item.map((t, index) => {
        if (index === action.index_in_array) {
          return { ...t, detail: action.value };
        } else {
          return t;
        }
      });
      return { ...data_pdf, activities: new_item };
    }
    case dispatchTYPE.activities_pickuptime: {
      let new_item = [...data_pdf.activities];
      new_item = new_item.map((t, index) => {
        if (index === action.index_in_array) {
          return { ...t, pickuptime: action.value };
        } else {
          return t;
        }
      });
      return { ...data_pdf, activities: new_item };
    }

    case dispatchTYPE.activities_transportation: {
      let new_item = [...data_pdf.activities];
      new_item = new_item.map((t, index) => {
        if (index === action.index_in_array) {
          return { ...t, transportation: action.value };
        } else {
          return t;
        }
      });
      return { ...data_pdf, activities: new_item };
    }

    case dispatchTYPE.activities_guide: {
      let new_item = [...data_pdf.activities];
      new_item = new_item.map((t, index) => {
        if (index === action.index_in_array) {
          return { ...t, guide: action.value };
        } else {
          return t;
        }
      });
      return { ...data_pdf, activities: new_item };
    }

    case dispatchTYPE.activities_deleting: {
      let new_item = [...data_pdf.activities];
      new_item.splice(action.index_in_array, 1);
      return { ...data_pdf, activities: new_item };
    }

    case dispatchTYPE.activities_adding: {
      let new_item = [...data_pdf.activities];
      let new_value = action.value;
      let new_accom = action.accom.find((acc) => {
        return acc.code == action.value.accomodation_code;
      });
      new_value.accommondation = new_accom;

      new_item.splice(action.index_in_array + 1, 0, new_value);

      return { ...data_pdf, activities: new_item };
    }

    case dispatchTYPE.accommondation_regenerate: {
      return { ...data_pdf, accommondation: action.value };
    }

    case dispatchTYPE.accommondation_deleting: {
      let new_item = [...data_pdf.accommondation];
      new_item.splice(action.index_in_array, 1);
      return { ...data_pdf, accommondation: new_item };
    }

    case dispatchTYPE.accommondation_adding: {
      let new_item = [...data_pdf.accommondation];
      new_item.push(action.value);
      return { ...data_pdf, accommondation: new_item };
    }

    case dispatchTYPE.accommondation_hotel: {
      let new_item = [...data_pdf.accommondation];
      new_item = new_item.map((t, index) => {
        if (index === action.index_in_array) {
          return { ...t, hotel: action.value };
        } else {
          return t;
        }
      });
      return { ...data_pdf, accommondation: new_item };
    }

    case dispatchTYPE.accommondation_type: {
      let new_item = [...data_pdf.accommondation];
      new_item = new_item.map((t, index) => {
        if (index === action.index_in_array) {
          return { ...t, type: action.value };
        } else {
          return t;
        }
      });
      return { ...data_pdf, accommondation: new_item };
    }

    case dispatchTYPE.accommondation_status: {
      let new_item = [...data_pdf.accommondation];
      new_item = new_item.map((t, index) => {
        if (index === action.index_in_array) {
          return { ...t, status: action.value };
        } else {
          return t;
        }
      });
      return { ...data_pdf, accommondation: new_item };
    }
    case dispatchTYPE.accommondation_remark: {
      let new_item = [...data_pdf.accommondation];
      new_item = new_item.map((t, index) => {
        if (index === action.index_in_array) {
          return { ...t, remark: action.value };
        } else {
          return t;
        }
      });
      return { ...data_pdf, accommondation: new_item };
    }
    case dispatchTYPE.accommondation_address: {
      let new_item = [...data_pdf.accommondation];
      new_item = new_item.map((t, index) => {
        if (index === action.index_in_array) {
          return { ...t, address: action.value };
        } else {
          return t;
        }
      });
      return { ...data_pdf, accommondation: new_item };
    }

    case dispatchTYPE.draft_title_pdf: {
      return { ...data_pdf, draft_title_pdf: action.value };
    }
    case dispatchTYPE.detail_title_pdf: {
      return { ...data_pdf, detail_title_pdf: action.value };
    }

    default: {
      throw Error("Unknown action: " + action.type);
    }
  }
}
