import React, { useState, useEffect } from "react";
import { Button, TextField, Box, Typography, Avatar } from "@mui/material";
import {
  API_edit_currency_rate,
  API_get_currency_rate,
  GG_AUTH_LINK,
} from "../utils/http";
import GoogleIcon from "@mui/icons-material/Google";

const AdminRate = ({}) => {
  const [data, set_data] = useState(null);
  const [showlogin, set_showlogin] = useState(false);

  async function GetData() {
    API_get_currency_rate((x) => {
      if (x.success) {
        x = x.data;

        if (x.email == null) {
          set_showlogin(true);
        }

        set_data(x);
      } else {
      }
    });
  }

  async function EditData() {
    await API_edit_currency_rate(data._id, data, (x) => {
      console.log(x);
    });
  }

  useEffect(() => {
    GetData();
  }, []);

  if (data == null) {
    return null;
  } else {
    return (
      <Box style={{ flexDirection: "column", display: "flex" }}>
        <Typography style={{ marginTop: 10 }} variant="h5">
          Admin mail
        </Typography>
        {showlogin ? (
          <Button
            onClick={() => window.open(GG_AUTH_LINK, "_blank")}
            component="label"
            role={undefined}
            variant="contained"
            tabIndex={-1}
            startIcon={<GoogleIcon />}
          >
            Admin login google
          </Button>
        ) : (
          <Box>
            <Avatar src={data.picture} sx={{ width: 56, height: 56 }} />
            <Typography style={{ marginTop: 10 }}>
              Email: {data.email}
            </Typography>
            <Typography style={{ marginTop: 10 }}>Name: {data.name}</Typography>
          </Box>
        )}

        <Typography style={{ marginTop: 10 }} variant="h5">
          Rate
        </Typography>
        <TextField
          style={{ margin: 5, width: 400 }}
          id="rate AUD"
          label="rate AUD"
          variant="outlined"
          value={data.currency_rate.AUD}
          onChange={(e) => {
            set_data({
              ...data,
              currency_rate: {
                ...data.currency_rate,
                AUD: e.target.value,
              },
            });
          }}
        />
        <TextField
          style={{ margin: 5, width: 400 }}
          id="rate USD"
          label="rate USD"
          variant="outlined"
          value={data.currency_rate.USD}
          onChange={(e) => {
            set_data({
              ...data,
              currency_rate: {
                ...data.currency_rate,
                USD: e.target.value,
              },
            });
          }}
        />

        <TextField
          style={{ margin: 5, width: 400 }}
          id="rate single"
          label="rate single"
          variant="outlined"
          value={data.customer_rate.single}
          onChange={(e) => {
            set_data({
              ...data,
              customer_rate: {
                ...data.customer_rate,
                single: e.target.value,
              },
            });
          }}
        />
        <TextField
          style={{ margin: 5, width: 400 }}
          id="rate double"
          label="rate double"
          variant="outlined"
          value={data.customer_rate.double}
          onChange={(e) => {
            set_data({
              ...data,
              customer_rate: {
                ...data.customer_rate,
                double: e.target.value,
              },
            });
          }}
        />

        <Button
          style={{ margin: 5, width: 400 }}
          variant="outlined"
          onClick={() => {
            console.log(data);
            EditData();
          }}
        >
          save rate
        </Button>
      </Box>
    );
  }
};

export { AdminRate };
