import React, { useState, useEffect } from "react";
import {
  Button,
  Box,
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  TextField,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import CopyAllRoundedIcon from "@mui/icons-material/CopyAllRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import {
  API_clone_vendorgroup,
  API_delete_vendorgroup_id,
  API_edit_vendorgroup,
  API_get_activities,
  API_get_vendor,
  API_get_vendorgroup,
} from "./utils/http";

import { COLUMN_SERVICE, COLUMN_VENDOR } from "./utils/constDispatch";
import VendorInput from "./components/VendorInput";
import { AlertCus } from "./components/AlertCus";
import AppBarSearchBox from "./components/SearchBox";
import { DialogCus } from "./components/DialogCus";
import { checkOper_or_admin, render_number } from "./utils/tool";

export default function Vendor() {
  const [search_text, set_search_text] = useState("");
  const [row_click, set_row_click] = useState(null);
  const [dataAPI, set_data] = useState(null);
  const [edit_ok, set_edit_ok] = useState(null);
  const [create_ok, set_create_ok] = useState(null);

  const [clone_ok, set_clone_ok] = useState(null);
  const [accom, set_accom] = useState(null);
  const [activ, set_activ] = useState(null);

  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);

  const handleClickOpen = () => {
    setOpen(!open);
  };
  const handleClickOpen1 = () => setOpen1(!open1);
  const handleClickOpen2 = () => setOpen2(!open2);

  const [data_filter, set_data_filter] = useState(null);
  const [new_code, set_new_code] = useState(null);

  const [selected_id, set_selected_id] = useState(null);
  const [rowSelection, setRowSelection] = useState([]);

  const [data_services, set_data_services] = useState(null);
  const [data_services_filter, set_data_services_filter] = useState([]);

  const handle_search = (search_text) => {
    if (search_text.replace(/\W/g, "").toLocaleLowerCase() == "") {
      set_data_filter(dataAPI);
    } else {
      let data_tmp = dataAPI.filter((x) => {
        let str = x.code + x.type;

        str = str.replace(/\W/g, "").toLocaleLowerCase();
        let str_search = search_text.replace(/\W/g, "").toLocaleLowerCase();
        return str.includes(str_search);
      });

      set_data_filter(data_tmp);
    }
  };

  async function get_data_activities() {
    await API_get_activities((data) => {
      if (data.success) {
        set_accom(data.accom);
        set_activ(data.data);
      }
    });
  }

  async function get_data_services() {
    let data_1 = [];

    await API_get_vendor((data) => {
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        element.price = render_number(element.price);
        element.price_for_customer = render_number(element.price_for_customer);

        try {
          element.link = element.attach_acti.length;
        } catch (error) {
          console.log(error);
          element.link = 0;
        }
        data_1.push(element);
      }
    });

    set_data_services(data_1);
  }

  function get_data_vendorgroup() {
    API_get_vendorgroup((data) => {
      let data_1 = data;

      set_data(data_1);
      set_data_filter(data_1);
      set_selected_id(null);
      setRowSelection([]);
    });

    // API_get_vendorgroup((data) => {
    //   set_data(data);
    //   set_data_filter(data);
    //   set_selected_id(null);
    //   setRowSelection([]);
    // });
  }

  function confirmClone() {
    API_clone_vendorgroup(selected_id, new_code, (data) => {
      if (data.success) {
        get_data_vendorgroup();
        handleClickOpen2();
        set_clone_ok(true);
      }
    });
  }

  function edit_data_vendor(id, data) {
    API_edit_vendorgroup(id, data, (x) => {
      set_edit_ok(x.success);
      get_data_vendorgroup();
    });
  }

  useEffect(() => {
    get_data_vendorgroup();
    get_data_activities();
    get_data_services();
  }, []);

  if (open) {
    return (
      <VendorInput
        accom={accom}
        activ={activ}
        init_data={row_click}
        onSaveClick={(id, data) => edit_data_vendor(id, data)}
        onGoBackClick={() => {
          handleClickOpen();
          get_data_vendorgroup();
          get_data_services();
        }}
      />
    );
  } else {
    return (
      <Box style={{ flexDirection: "column", display: "flex" }}>
        <DialogCus
          open={open2}
          title="Clone"
          handleClickOpen={handleClickOpen2}
          handleClickConfirm={confirmClone}
        >
          <DialogContent>
            <TextField
              placeholder="Enter new code"
              autoFocus
              value={new_code}
              onChange={(e) => set_new_code(e.target.value)}
              margin="dense"
              id="code"
              label="code"
              fullWidth
              variant="standard"
            />
          </DialogContent>
        </DialogCus>

        <AppBarSearchBox
          title="Vendor"
          value={search_text}
          onChange={(e) => {
            if (e.target.value == "") {
              handle_search("");
              set_selected_id(null);
            }
            set_search_text(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.keyCode == 13) {
              handle_search(search_text);
              set_selected_id(null);
            }
          }}
        />
        <AlertCus condition={edit_ok} message="Edit" />
        <AlertCus condition={clone_ok} message="Clone" />
        <AlertCus condition={create_ok} message="Create" />

        {checkOper_or_admin() ? (
          <Box
            style={{ display: "flex", padding: 20, justifyContent: "flex-end" }}
          >
            <Button
              style={{ marginLeft: 20 }}
              variant="outlined"
              endIcon={<CopyAllRoundedIcon />}
              onClick={() => {
                get_data_vendorgroup();
                get_data_services();
              }}
            >
              Refesh
            </Button>

            <Button
              style={{ marginLeft: 20 }}
              variant="outlined"
              endIcon={<CopyAllRoundedIcon />}
              onClick={() => handleClickOpen2()}
              disabled={selected_id == null}
            >
              Clone
            </Button>
            <Button
              variant="outlined"
              disabled={selected_id == null}
              color="error"
              style={{ marginLeft: 20 }}
              endIcon={<DeleteRoundedIcon />}
              onClick={() => handleClickOpen1()}
            >
              Delete
            </Button>
          </Box>
        ) : null}

        <Dialog open={open1} onClose={handleClickOpen1}>
          <DialogTitle>Confirm deleting</DialogTitle>
          <DialogContent>
            <DialogContentText>Are you sure?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClickOpen1}>Close</Button>
            <Button
              onClick={() => {
                handleClickOpen1();
                API_delete_vendorgroup_id(selected_id, (x) => {
                  if (x.success) {
                    get_data_vendorgroup();
                  }
                });
              }}
            >
              confirm
            </Button>
          </DialogActions>
        </Dialog>
        <Box
          style={{
            paddingLeft: 20,
            paddingRight: 20,
          }}
        >
          {dataAPI != null && accom != null && data_services != null ? (
            <Box
              sx={{
                height: 700,
                width: "100%",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Box style={{ margin: 10 }}>
                <DataGrid
                  density="compact"
                  getRowId={(item) => item._id}
                  rows={data_filter}
                  columns={COLUMN_VENDOR}
                  initialState={{
                    pagination: { paginationModel: { pageSize: 20 } },
                  }}
                  pageSizeOptions={[5, 10, 20]}
                  rowSelectionModel={rowSelection}
                  onRowSelectionModelChange={(e) => {
                    setRowSelection(e);
                    set_selected_id(e[0]);
                    set_row_click(dataAPI.find((x) => x._id === e[0]));

                    let service_tmp = data_services.filter((x) => {
                      return x.vendorgroup_id == e[0];
                    });
                    set_data_services_filter(service_tmp);
                  }}
                  onCellDoubleClick={() => {
                    if (checkOper_or_admin()) {
                      handleClickOpen();
                    }
                  }}
                  disableMultipleRowSelection={true}
                />
              </Box>
              <Box style={{ margin: 10 }}>
                <DataGrid
                  density="compact"
                  getRowId={(item) => item._id}
                  rows={data_services_filter}
                  columns={COLUMN_SERVICE}
                  initialState={{
                    pagination: { paginationModel: { pageSize: 20 } },
                  }}
                  pageSizeOptions={[5, 10, 20]}
                  disableMultipleRowSelection={true}
                />
              </Box>
            </Box>
          ) : (
            <Box sx={{ width: "100%", marginTop: 10 }}>
              <LinearProgress />
            </Box>
          )}
        </Box>
      </Box>
    );
  }
}
