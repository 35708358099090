import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import _ from "lodash";
import { VendorItem } from "./VendorItem";

export default function ListVendorByDay({
  data_vendor,
  booking_details,
  vendor_details,
  ConfirmClick,
  DeleteClick,
  EditOperClick,
}) {
  let data_clean = _(vendor_details)
    .sortBy(["vendor_type", "day_index"])
    .groupBy((x) => x.day_index)
    .map((value, key) => ({
      // vendor_id: key,
      // vendor_code: value[0].vendor_code,
      day_index: value[0].day_index,
      day_index_detail: value[0].day_index_detail,
      booking_id: value[0].booking_id,
      data: value,
    }))
    .value();

  return (
    <Box style={{ marginTop: 20, marginBottom: 10 }}>
      {data_clean.map((item, index) => (
        <VendorItem
          item={item}
          index={index}
          booking_details={booking_details}
          vendor_details={vendor_details}
          DeleteClick={DeleteClick}
          ConfirmClick={ConfirmClick}
          EditOperClick={EditOperClick}
          SortByDay={true}
        />
      ))}
    </Box>
  );
}
