import React, { useState, useEffect } from "react";
import { Button, TextField, Box } from "@mui/material";

const AddUser = ({ clickAdd }) => {
  const [username, set_username] = useState("");
  const [new_password, set_new_password] = useState("");
  const [new_password_confirmation, set_new_password_confirmation] =
    useState("");

  const ConfirmBtn = () => {
    if (
      username != "" &&
      new_password != "" &&
      new_password == new_password_confirmation
    ) {
      return (
        <Button
          style={{ margin: 5, width: 400 }}
          variant="outlined"
          onClick={() => {
            clickAdd({ username: username, password: new_password });
          }}
        >
          confirm add user
        </Button>
      );
    }
  };

  return (
    <Box style={{ flexDirection: "column", display: "flex" }}>
      <TextField
        style={{ margin: 5, width: 400 }}
        id="username"
        label="username"
        variant="outlined"
        value={username}
        onChange={(e) => set_username(e.target.value)}
      />
      <TextField
        style={{ margin: 5, width: 400 }}
        id="new_password"
        type="password"
        label="new password"
        variant="outlined"
        value={new_password}
        onChange={(e) => set_new_password(e.target.value)}
      />

      <TextField
        style={{ margin: 5, width: 400 }}
        id="new_password_confirmation"
        type="password"
        label="retype new password"
        variant="outlined"
        value={new_password_confirmation}
        onChange={(e) => set_new_password_confirmation(e.target.value)}
      />

      <ConfirmBtn />
    </Box>
  );
};

export { AddUser };
