import React, { useState, useEffect } from "react";
import { Box, Tab, Tabs, Button, LinearProgress } from "@mui/material";
import CachedIcon from "@mui/icons-material/Cached";

import TabPDFDraft from "./TabPDFDraft";
import TabPDFDetail from "./TabPDFDetail";

import { API_get_bookings_id } from "../../utils/http";
import { checkOper_or_admin } from "../../utils/tool";
import OperDetail from "../operating/OperDetail";

export default function OutputRight({ booking_id }) {
  const [value, setValue] = useState(0);
  const [data_pdf, set_data] = useState(null);
  const [loading, set_loading] = useState(true);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const get_data_booking = async () => {
    await API_get_bookings_id(booking_id, (x) => {
      if (x.success) {
        set_data(x.data);
        set_loading(false);
      }
    });
  };

  useEffect(() => {
    get_data_booking();
  }, []);

  if (loading) {
    return (
      <Box sx={{ width: "100%", marginTop: 10 }}>
        <LinearProgress />
      </Box>
    );
  } else {
    return (
      <Box style={{ display: "flex", flex: 1, flexDirection: "column" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Draft booking" />
          <Tab label="Detail Itinerary" />
          <Tab label="Price list" />
        </Tabs>
        <Box style={{ height: "90%", overflow: "hidden", overflowY: "scroll" }}>
          {value == 0 ? (
            <Box sx={{ display: "flex", height: "90vh" }}>
              <Button
                style={{ position: "absolute", bottom: 100, right: 30 }}
                variant="contained"
                endIcon={<CachedIcon />}
                onClick={() => {
                  get_data_booking();
                }}
              >
                Re-generate pdf file
              </Button>
              <TabPDFDraft data_pdf={data_pdf} booking_id={booking_id} />
            </Box>
          ) : null}
          {value == 1 ? (
            <Box sx={{ display: "flex", height: "90vh" }}>
              <Button
                style={{ position: "absolute", bottom: 100, right: 30 }}
                variant="contained"
                endIcon={<CachedIcon />}
                onClick={() => {
                  get_data_booking();
                }}
              >
                Re-generate pdf file
              </Button>
              <TabPDFDetail data_pdf={data_pdf} booking_id={booking_id} />{" "}
            </Box>
          ) : null}

          {value == 2 ? (
            <Box sx={{ height: "90vh" }}>
              <OperDetail booking_id={booking_id} />
            </Box>
          ) : null}
        </Box>
      </Box>
    );
  }
}
