import React, { useState, useEffect } from "react";
import { TextField, Typography, Box } from "@mui/material";

import { useTasks, useTasksDispatch } from "../AppContext";
import { dispatchTYPE } from "../utils/constDispatch";

export default function InputLeftPrice() {
  const data_pdf = useTasks();
  const dispatch = useTasksDispatch();

  return (
    <div>
      <Typography variant="h4">PRICE</Typography>

      {data_pdf.price.map((item, index) => (
        <div style={{ display: "flex" }}>
          <TextField
            style={{ margin: 5, flex: 1 }}
            id="first_deposit"
            variant="outlined"
            value={item.label}
            multiline
            minRows={2}
            onChange={(e) => {
              dispatch({
                type: dispatchTYPE.price_label,
                value: e.target.value,
                index_in_array: index,
              });
            }}
          />
          <TextField
            style={{ margin: 5, flex: 1 }}
            id="first_deposit"
            variant="outlined"
            value={item.price}
            multiline
            minRows={2}
            onChange={(e) => {
              dispatch({
                type: dispatchTYPE.price_price,
                value: e.target.value,
                index_in_array: index,
              });
            }}
          />
          <TextField
            style={{ margin: 5, flex: 3 }}
            id="first_deposit"
            variant="outlined"
            value={item.value}
            multiline
            minRows={2}
            onChange={(e) => {
              dispatch({
                type: dispatchTYPE.price_value,
                value: e.target.value,
                index_in_array: index,
              });
            }}
          />
        </div>
      ))}
      <Box
        style={{ flexDirection: "column", display: "flex", marginRight: 15 }}
      >
        <Typography variant="h4">PRICE Include</Typography>
        <TextField
          id="price_include"
          variant="outlined"
          fullWidth
          value={data_pdf.price_include}
          multiline
          minRows={2}
          onChange={(e) => {
            dispatch({
              type: dispatchTYPE.price_include,
              value: e.target.value,
            });
          }}
        />

        <Typography variant="h4">PRICE Exclude</Typography>
        <TextField
          id="price_exclude"
          variant="outlined"
          value={data_pdf.price_exclude}
          multiline
          minRows={2}
          onChange={(e) => {
            dispatch({
              type: dispatchTYPE.price_exclude,
              value: e.target.value,
            });
          }}
        />

        <Typography variant="h4">Remember to bring</Typography>
        <TextField
          id="remember to bring"
          variant="outlined"
          value={data_pdf.remember_to_bring}
          multiline
          minRows={2}
          onChange={(e) => {
            dispatch({
              type: dispatchTYPE.remember_to_bring,
              value: e.target.value,
            });
          }}
        />

        <Typography variant="h4">Note</Typography>
        <TextField
          id="note"
          variant="outlined"
          value={data_pdf.note}
          multiline
          minRows={2}
          onChange={(e) => {
            dispatch({
              type: dispatchTYPE.note,
              value: e.target.value,
            });
          }}
        />
      </Box>
    </div>
  );
}
