import { View, Text, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 0.5,
  },
  tableRow: { flexDirection: "row" },
  tableCellBold: {
    margin: 5,
    fontSize: 11,
    lineHeight: "1.5px",
    fontFamily: "Helvetica-Bold",
    textAlign: "center",
  },
  tableCellBold_small: {
    margin: 5,
    fontSize: 11,
    lineHeight: "1.5px",
    fontFamily: "Helvetica-Bold",
    textAlign: "center",
  },
  tableCell: {
    margin: 10,
    fontSize: 11,
    lineHeight: "1.5px",
    fontFamily: "Helvetica",
  },
  tableCell_small: {
    margin: 5,
    fontSize: 10,
    lineHeight: "1.5px",
    fontFamily: "Helvetica",
  },
  tableRow_per: {
    borderStyle: "solid",
    borderTopWidth: 0.5,
    borderBottomWidth: 0.5,
    borderLeftWidth: 1,
    borderRightWidth: 0,
  },
  textPrice: {
    marginLeft: 10,
    marginTop: 5,
    fontSize: 11,
    lineHeight: "1.5px",
    textAlign: "left",
  },
  fontBold: {
    fontFamily: "Helvetica-Bold",
  },
  fontNormal: {
    fontFamily: "Helvetica",
  },
  textInfo: {
    marginLeft: 10,
    marginTop: 5,
    marginBottom: 5,
    fontSize: 11,
    textAlign: "left",
  },
  detail2Column: {
    marginLeft: 10,
    marginTop: 3,
    marginBottom: 3,
    fontSize: 11,
    textAlign: "left",
  },
  textDepo: {
    fontSize: 11,
    lineHeight: "1.5px",
    margin: 10,
  },
  alignHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    borderTopWidth: 1,
  },
});

const TableHeader = ({ children, ...props }) => (
  <View style={styles.tableRow}>
    <View
      style={{
        ...styles.tableRow_per,
        width: "13%",
        ...styles.alignHeader,
      }}
    >
      <Text style={styles.tableCellBold}>Date</Text>
    </View>
    <View
      style={{
        ...styles.tableRow_per,
        width: "37%",
        ...styles.alignHeader,
      }}
    >
      <Text style={styles.tableCellBold}>Activities</Text>
    </View>
    <View
      style={{
        ...styles.tableRow_per,
        width: "37%",
        ...styles.alignHeader,
      }}
    >
      <Text style={styles.tableCellBold}>Accommodation</Text>
    </View>
    <View
      style={{
        ...styles.tableRow_per,
        width: "13%",
        borderRightWidth: 1,
        ...styles.alignHeader,
      }}
    >
      <Text style={styles.tableCellBold}>Pick up Timings</Text>
    </View>
  </View>
);

const TableHeaderDetail = ({ children, ...props }) => (
  <View style={styles.tableRow}>
    <View
      style={{ ...styles.tableRow_per, width: "10%", ...styles.alignHeader }}
    >
      <Text style={styles.tableCellBold}>Day</Text>
    </View>
    <View
      style={{ ...styles.tableRow_per, width: "12%", ...styles.alignHeader }}
    >
      <Text style={styles.tableCellBold}>Date</Text>
    </View>
    <View
      style={{ ...styles.tableRow_per, width: "38%", ...styles.alignHeader }}
    >
      <Text style={styles.tableCellBold}>Activities</Text>
    </View>
    <View
      style={{ ...styles.tableRow_per, width: "15%", ...styles.alignHeader }}
    >
      <Text style={styles.tableCellBold}>Accommodation</Text>
    </View>
    <View
      style={{ ...styles.tableRow_per, width: "13%", ...styles.alignHeader }}
    >
      <Text style={styles.tableCellBold}>Transportation</Text>
    </View>
    <View
      style={{
        ...styles.tableRow_per,
        width: "12%",
        ...styles.alignHeader,
        borderRightWidth: 1,
      }}
    >
      <Text style={styles.tableCellBold}>Guide</Text>
    </View>
  </View>
);

const TableRowDetail = ({ children, ...props }) => {
  let bg_color = "white";
  if (props.index % 2 == 0) {
    bg_color = "#ECEFF1";
  }

  return (
    <View
      style={{ ...styles.tableRow, backgroundColor: bg_color }}
      wrap={false}
    >
      {children}
      <View style={{ ...styles.tableRow_per, width: "10%" }}>
        <Text style={styles.tableCellBold_small}>
          {props.day_showing[props.index].day}
        </Text>
      </View>
      <View style={{ ...styles.tableRow_per, width: "12%" }}>
        <Text style={{ ...styles.tableCell_small, textAlign: "center" }}>
          {props.day_showing[props.index].date}
        </Text>
      </View>
      <View style={{ ...styles.tableRow_per, width: "38%" }}>
        <Text style={styles.tableCell_small}>{props.data.activities}</Text>
      </View>
      <View style={{ ...styles.tableRow_per, width: "15%" }}>
        <Text style={styles.tableCell_small}>
          {props.data.accommondation.city}
        </Text>
      </View>
      <View style={{ ...styles.tableRow_per, width: "13%" }}>
        <Text style={styles.tableCell_small}>{props.data.transportation}</Text>
      </View>
      <View
        style={{ ...styles.tableRow_per, width: "12%", borderRightWidth: 1 }}
      >
        <Text style={styles.tableCell_small}>{props.data.guide}</Text>
      </View>
    </View>
  );
};

const TableRowDetailAcc = ({ children, ...props }) => {
  let bg_color = "white";
  if (props.index % 2 == 0) {
    bg_color = "#ECEFF1";
  }

  if (props.data.city == "") {
    return null;
  }

  return (
    <View
      style={{ ...styles.tableRow, backgroundColor: bg_color }}
      wrap={false}
    >
      {children}
      <View style={{ ...styles.tableRow_per, width: "18%" }}>
        <Text
          style={{
            ...styles.tableCellBold_small,
            textAlign: "left",
            marginLeft: 10,
          }}
        >
          {props.data.city}
        </Text>
      </View>
      <View style={{ ...styles.tableRow_per, width: "50%" }}>
        <Text style={styles.tableCell_small}>
          {props.data.hotel}
          {"\n"}
          {props.data.address}
        </Text>
      </View>
      <View style={{ ...styles.tableRow_per, width: "15%" }}>
        <Text style={styles.tableCell_small}>{props.data.type}</Text>
      </View>
      <View style={{ ...styles.tableRow_per, width: "15%" }}>
        <Text style={styles.tableCell_small}>{props.data.status}</Text>
      </View>
      <View
        style={{
          ...styles.tableRow_per,
          width: "15%",
          borderRightWidth: 1,
        }}
      >
        <Text style={styles.tableCell_small}>{props.data.remark}</Text>
      </View>
    </View>
  );
};
const TableHeaderDetailAcc = ({ children, ...props }) => (
  <View style={styles.tableRow} wrap={false}>
    <View style={{ ...styles.tableRow_per, width: "18%", borderTopWidth: 1 }}>
      <Text style={{ ...styles.tableCellBold }}>City</Text>
    </View>
    <View style={{ ...styles.tableRow_per, width: "50%", borderTopWidth: 1 }}>
      <Text style={styles.tableCellBold}>Hotel</Text>
    </View>
    <View style={{ ...styles.tableRow_per, width: "15%", borderTopWidth: 1 }}>
      <Text style={styles.tableCellBold}>Type</Text>
    </View>
    <View style={{ ...styles.tableRow_per, width: "15%", borderTopWidth: 1 }}>
      <Text style={styles.tableCellBold}>Status</Text>
    </View>
    <View
      style={{
        ...styles.tableRow_per,
        width: "15%",
        borderRightWidth: 1,
        borderTopWidth: 1,
      }}
    >
      <Text style={styles.tableCellBold}>Remark</Text>
    </View>
  </View>
);

const TableRowNoteHotel = () => (
  <View style={styles.tableRow} wrap={false}>
    <View
      style={{
        ...styles.tableRow_per,
        width: "100%",
        borderRightWidth: 1,
        borderBottomWidth: 1,
      }}
    >
      <Text
        style={{
          ...styles.tableCell,
          fontFamily: "Helvetica-Oblique",
          textAlign: "center",
        }}
      >
        Hotel lists are flexible and may be changed by the same standard and
        quality.
      </Text>
    </View>
  </View>
);

const TableRow = ({ children, ...props }) => {
  let bg_color = "white";
  if (props.index % 2 == 0) {
    bg_color = "#ECEFF1";
  }

  return (
    <View
      style={{ ...styles.tableRow, backgroundColor: bg_color }}
      wrap={false}
    >
      {children}
      <View style={{ ...styles.tableRow_per, width: "13%" }}>
        <Text style={styles.tableCellBold_small}>{props.day}</Text>
      </View>
      <View style={{ ...styles.tableRow_per, width: "37%" }}>
        <Text style={styles.tableCell_small}>{props.data.activities}</Text>
      </View>
      <View style={{ ...styles.tableRow_per, width: "37%" }}>
        <Text style={{ ...styles.tableCell_small }}>
          {props.data.accommondation.hotel}
          {"\n"}
          {props.data.accommondation.address}
          {"\n"}
          {props.data.accommondation.type}
        </Text>
      </View>
      <View
        style={{ ...styles.tableRow_per, width: "13%", borderRightWidth: 1 }}
      >
        <Text style={styles.tableCell_small}>{props.data.pickuptime}</Text>
      </View>
    </View>
  );
};

const TableTourName = ({ children, ...props }) => (
  <View style={styles.tableRow} wrap={false}>
    <View
      style={{
        ...styles.tableRow_per,
        width: "100%",
        padding: 10,
        borderRightWidth: 1,
      }}
    >
      <Text style={{ fontSize: 11, fontFamily: "Helvetica-Bold" }}>
        Tour Price
      </Text>
      <Text
        style={{
          marginTop: 5,
          fontSize: 11,
          lineHeight: "1.5px",
          fontFamily: "Helvetica",
        }}
      >
        {props.data[0].value}
      </Text>
      <Text
        style={{ marginTop: 5, fontSize: 11, fontFamily: "Helvetica-Bold" }}
      >
        {props.data[1].label}
        {": "}
        {props.data[1].price}
      </Text>
    </View>
  </View>
);

const FirstDeposit = ({ children, ...props }) => {
  if (props.value == "") {
    return null;
  } else {
    return (
      <View style={styles.tableRow} wrap={false}>
        {children}
        <View style={{ ...styles.tableRow_per, width: "80%" }}>
          <Text
            style={{
              ...styles.textDepo,
              ...styles.fontBold,
              marginBottom: 2,
            }}
          >
            {props.label}
          </Text>
          <Text
            style={{
              fontFamily: "Helvetica",
              ...styles.textDepo,
              marginTop: 2,
            }}
          >
            {props.value}
          </Text>
        </View>
        <View
          style={{
            ...styles.tableRow_per,
            width: "20%",
            borderRightWidth: 1,
          }}
        >
          <Text
            style={{
              ...styles.textDepo,
              ...styles.fontBold,
              textAlign: "center",
            }}
          >
            {props.price}
          </Text>
        </View>
      </View>
    );
  }
};

const HeadingWithColor = ({ children, ...props }) => (
  <View style={{ marginTop: 10, marginBottom: 5 }} wrap={false}>
    {children}
    <View style={{ width: "100%", backgroundColor: "orange" }}>
      <Text style={{ ...styles.detail2Column, fontFamily: "Helvetica-Bold" }}>
        {props.label}
      </Text>
    </View>
  </View>
);

const Detail2Column = ({ children, ...props }) => (
  <View style={{ flexDirection: "row" }}>
    {children}
    <View style={{ width: "30%" }}>
      <Text style={{ ...styles.detail2Column, fontFamily: "Helvetica-Bold" }}>
        {props.label}
      </Text>
    </View>
    <View style={{ width: "70%" }}>
      <Text style={{ ...styles.detail2Column, fontFamily: "Helvetica" }}>
        {": "}
        {props.value}
      </Text>
    </View>
  </View>
);

function PriceDetail({ children, ...props }) {
  let textupper = props.label + " " + props.price;
  if (props.price == null) {
    textupper = props.label;
  }
  textupper = textupper.toUpperCase();
  return (
    <View>
      {children}
      {props.label == "" ? null : (
        <View>
          <Text style={{ ...styles.textPrice, ...styles.fontBold }}>
            {textupper}
          </Text>
        </View>
      )}
      <View>
        <Text style={{ ...styles.textPrice, ...styles.fontNormal }}>
          {props.value}
        </Text>
      </View>
    </View>
  );
}

const PriceList = ({ children, ...props }) => (
  <View>
    <Text style={{ ...styles.textPrice, ...styles.fontNormal, marginLeft: 30 }}>
      {" •  "}
      {props.label}
    </Text>
  </View>
);

const InfoCompany = ({ children, ...props }) => (
  <View style={{ flexDirection: "row" }}>
    <View style={{ width: "20%" }}>
      <Text style={{ fontFamily: "Helvetica-Bold", ...styles.textInfo }}>
        {props.label}
      </Text>
    </View>
    <View style={{ width: "70%" }}>
      <Text style={{ fontFamily: "Helvetica", ...styles.textInfo }}>
        {props.value}
      </Text>
    </View>
  </View>
);

export {
  TableHeader,
  TableHeaderDetail,
  TableRow,
  TableRowDetail,
  TableTourName,
  FirstDeposit,
  HeadingWithColor,
  Detail2Column,
  PriceDetail,
  PriceList,
  InfoCompany,
  TableHeaderDetailAcc,
  TableRowDetailAcc,
  TableRowNoteHotel,
};
