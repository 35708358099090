import React, { useState, useEffect } from "react";
import { TextField, Button, Typography, Box } from "@mui/material";
import PriceCurrency from "./PriceCurrency";
import { render_number, render_number_onchange } from "../../utils/tool";

export default function EditOper({ data_input, onSaveClick, DeleteClick }) {
  let data_input_clean = data_input;
  data_input_clean.price = render_number(data_input.price);
  data_input_clean.price_for_customer = render_number(
    data_input.price_for_customer
  );

  const [init_data, set_init_data] = useState(data_input_clean);

  return (
    <Box
      style={{
        flexDirection: "column",
        display: "flex",
        padding: 10,
        alignItem: "flex-start",
      }}
    >
      <Typography variant="h6">Vendor code: {init_data.vendor_code}</Typography>
      <Typography variant="h6">Vendor type: {init_data.vendor_type}</Typography>
      <Typography variant="h6">
        Service code: {init_data.service_code}
      </Typography>

      <Typography>Booking number: {init_data.booking_ref}</Typography>
      {/* <Typography>Day travel: {init_data.day_index_detail}</Typography> */}

      <Box style={{ flexDirection: "row", display: "flex", marginTop: 20 }}>
        <TextField
          style={{ flex: 1, maxWidth: 600, margin: 5 }}
          id="price"
          // label="price for admin"
          label="double"
          variant="outlined"
          value={init_data.price}
          onChange={(e) => {
            set_init_data({
              ...init_data,
              price: render_number_onchange(e.target.value),
            });
          }}
        />
        <PriceCurrency
          defaultValue={init_data.price_currency}
          onChange={(value) =>
            set_init_data({ ...init_data, price_currency: value })
          }
        />
      </Box>

      <Box style={{ flexDirection: "row", display: "flex", marginTop: 20 }}>
        <TextField
          style={{ flex: 1, maxWidth: 600, margin: 5 }}
          id="price"
          // label="price for admin"
          label="double"
          variant="outlined"
          value={init_data.price_for_customer}
          onChange={(e) => {
            set_init_data({
              ...init_data,
              price_for_customer: render_number_onchange(e.target.value),
            });
          }}
        />
        <PriceCurrency
          defaultValue={init_data.price_for_customer_currency}
          onChange={(value) =>
            set_init_data({ ...init_data, price_for_customer_currency: value })
          }
        />
      </Box>

      <TextField
        style={{ flex: 1, maxWidth: 600, margin: 5 }}
        id="note"
        label="note"
        variant="outlined"
        value={init_data.note}
        multiline={true}
        onChange={(e) => {
          set_init_data({ ...init_data, note: e.target.value });
        }}
      />

      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: 30,
        }}
      >
        <Button
          variant="contained"
          color="error"
          onClick={() => {
            DeleteClick(init_data._id);
          }}
        >
          Delete
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            let data_tmp = {
              ...init_data,
              price: Number(init_data.price.replaceAll(/,/g, "")),
              price_for_customer: Number(
                init_data.price_for_customer.replaceAll(/,/g, "")
              ),
            };

            onSaveClick(data_tmp);
          }}
        >
          save
        </Button>
      </Box>
    </Box>
  );
}
