import React from "react";
import { Alert } from "@mui/material";

const AlertCus = ({ condition, message, error_text }) => {
  if (condition != null) {
    if (condition) {
      return <Alert severity="success">{message} success</Alert>;
    } else {
      return (
        <Alert severity="error">
          {message} not success: {error_text}
        </Alert>
      );
    }
  } else {
    return null;
  }
};

export { AlertCus };
