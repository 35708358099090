import React, { useState, useEffect } from "react";
import { Box, TextField, Autocomplete } from "@mui/material";

export default function PriceCurrency({ defaultValue, onChange }) {
  return (
    <Box style={{ margin: 5, flex: 1, maxWidth: 600 }}>
      <Autocomplete
        //   isOptionEqualToValue={(option, value) => option._id === value._id}
        disablePortal
        disableClearable
        options={["VND", "USD", "AUD", "CONG_NO"]}
        sx={{ width: 150 }}
        getOptionLabel={(option) => option}
        defaultValue={defaultValue == null ? "VND" : defaultValue}
        onChange={(e, value) => onChange(value)}
        renderInput={(params) => (
          <TextField {...params} label="price currency" value={params.code} />
        )}
      />
    </Box>
  );
}
