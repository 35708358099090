import SearchIcon from "@mui/icons-material/Search";
import { InputBase, AppBar, Toolbar, Typography, Button } from "@mui/material";
import { styled, alpha } from "@mui/material/styles";

export default function AppBarUser({ title, value, onLogout }) {
  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          {title}
        </Typography>

        {/* {value == null ? null : (
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ "aria-label": "search" }}
              value={value}
              onChange={onChange}
              onKeyDown={onKeyDown}
            />
          </Search>
        )} */}
        <Button color="inherit" onClick={() => onLogout()}>
          Logout
        </Button>
      </Toolbar>
    </AppBar>
  );
}
