import React from "react";
import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";

const DialogCus = ({
  title,
  open,
  handleClickOpen,
  handleClickConfirm,
  children,
}) => {
  return (
    <Dialog open={open} onClose={handleClickOpen}>
      <DialogTitle>{title}</DialogTitle>
      {children}
      <DialogActions>
        <Button onClick={handleClickOpen}>Close</Button>

        {handleClickConfirm == null ? null : (
          <Button onClick={handleClickConfirm}>Confirm</Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export { DialogCus };
