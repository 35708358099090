import { StyleSheet } from "@react-pdf/renderer";

export const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    marginBottom: 15,
    fontSize: 14,
    textAlign: "center",
    fontFamily: "Helvetica-Bold",
  },
  notetext: {
    marginTop: 10,
    fontSize: 11,
    textAlign: "center",
    fontFamily: "Helvetica-Oblique",
  },
  textBold: {
    marginLeft: 10,
    marginTop: 10,
    fontSize: 11,
    fontFamily: "Helvetica-Bold",
    textAlign: "left",
  },
  textNote: {
    marginLeft: 10,
    marginTop: 5,
    marginBottom: 10,
    fontSize: 11,
    fontFamily: "Helvetica",
    lineHeight: "1.5px",
    textAlign: "left",
  },
});
